<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

export default {
  name:'SkeletonLeft',
  functional:true,
  props:{
    cateType: {
      type: String,
      default: 'sidecat',
    },
  },
  render(h, {props}){
    const { cateType } = props
    const { cssRight = false } = useAppConfigs().$envs || {}
    const renderItems = () => {
      const items = []
      for (let i = 0; i < 20; i++) {
        items.push(
          <li
            key={i}
            class={'c-skeleton__sidecat-left-item ' + cateType}
          >
            { cateType === 'sidecat' ? <div class="sidecat-left__img"></div> : null }
            <div class="sidecat-left__name"></div>
            {cateType === 'sidecat' ? <Icon class="bs-left-item_arr" name="sui_icon_more_right_16px" is-rotate={cssRight} size="16px" color="#959595" /> : null}
          </li>,
        )
      }
      return items
    }
    return (
      <ul class="c-skeleton__sidecat-left" >
        {
          renderItems()
        }
      </ul>
    )
  },
}
</script>
<style lang="less">
  .c-skeleton {
    &__sidecat-left {
      width: 100%;
      height: 100%;
      overflow-y: hidden;
      background-color: #FFF;
    }
     &__sidecat-left-item {
      display: flex;
      align-items: center;
      height: min(16vw,86.4px);
      margin-left: 0;
      padding-left: 20px;
      padding-right: 12px;
      text-align: left;
      &.cat{
        padding: 0;
        height: auto;
        margin: 11.25px 8px;
        .sidecat-left__name{
          height: 30.5px;
          margin: 0;
        }
      }
    }
  }

  .sidecat-left {
    &__img {
      width: min(11.733vw,64px);
      height: min(11.733vw,64px);
      background: #f5f5f5;
      border-radius: 100%;
    }
    &__name {
      flex: 1;
      width: min(30vw,162px);
      height: min(3.733vw,22px);
      margin: 0 24px 0 12px;
      background: #f5f5f5;
    }
  }
</style>
