import { ref } from 'vue'
import { appendTwoCateSa } from './useAnalysis'

const cacheContainer = {
  // 缓存容器
  leftContent: {},
  rightContent: {},
  batchContent: {},
  channelOneCate: {},
}

function getOneCateSa(item, index) {
  const id = item?.firstFloorContent?.props?.id || '-'
  const oneCateName = item?.firstFloorContent?.props?.metaData?.categoryName
  const first_category_list = `${index + 1}\`${oneCateName}\`${id}`
  const node_id = item?.firstFloorContent?.props?.metaData?.categoryId || ''
  return { first_category_list, node_id }
}

// const taskManager = {}
// function genTask(key, fn, params){
//     let task = taskManager[key]
//     if(!task || task.lastTime > Date.now()){
//         taskFn = new Promise((reslove) => {
//             fn(params).then(res => reslove(res))
//         })
//         task = {taskFn, lastTime:Date.now() + 10 * 1000}
//         taskManager[key] = task
//     }
//     return task.taskFn
// }

const useData = (apis, cateType, getTopNavSa, fsData) => {
  const topLoading = ref(true)
  const leftLoading = ref(true)
  const rightLoading = ref(true)
  const hasInitView = ref(false)
  const activeChannel = ref({
    // 选中的频道
    channel: {},
    index: 0,
  })

  const activeLeftContent = ref({
    // 选中的左侧内容
    cate: {},
    index: 0,
  })
  const topNav = ref([]) // 顶部tab 数据
  const leftContent = ref([]) // 左侧内容 数据
  const rightContent = ref([]) // 右侧内容 数据

  const initFsView = (fsData) => {
    // 首屏数据初始化
    if (!fsData || !fsData.rightContent){
      console.log('请配置完整的category数据');
      return
    } 
    topLoading.value = false
    leftLoading.value = false
    rightLoading.value = false
    const { channelId } = fsData

    let channelIndex = 0
    // let { channel } = fsData.activeChannel
    topNav.value = (fsData.topNav || []).map((item, index) => {
      delete item.newTabData
      item.saInfo = getTopNavSa(item, index)
      if(channelId == item.id){
        channelIndex = index
      }
      return item
    })
    const channel = topNav.value[channelIndex]
    activeChannel.value = { channel , index: channelIndex }

    const lefts = (fsData.leftContent?.contents || []).map((item, index) => {
      item.saInfo = getOneCateSa(item, index)
      return item
    })
    leftContent.value = { ...fsData.leftContent, contents: lefts }
  
    const cate = lefts[fsData.oneCate]
    if(cate){
      activeLeftContent.value = { cate, index: fsData.oneCate }
    }else{
      activeLeftContent.value = { cate: lefts[0], index: 0 }
    }
    appendTwoCateSa(fsData.rightContent, activeChannel.value, activeLeftContent.value)
    rightContent.value = fsData.rightContent
    hasInitView.value = true
  }
  
  initFsView(fsData)
  // 获取顶部tab数据
  const getTopNav = async (onlyNav = false) => {
    if(!onlyNav) topLoading.value = true
    try {
      const res = await apis.getNavTabs()
      topNav.value = (res.info?.tabs || []).map((item, index) => {
        delete item.newTabData
        item.saInfo = getTopNavSa(item, index)
        return item
      })
      if(onlyNav) return
      const {channelId, oneCate = 0} = fsData || {}
      let channelIndex = 0
      if(channelId){
        channelIndex = topNav.value.findIndex(item => item.id == channelId)
        channelIndex = Math.max(channelIndex,0) // 查不到指定频道id时取第一项
      }
      const channel = topNav.value[channelIndex] || {}
      activeChannel.value = { channel, index: channelIndex }
      changeChannel({ channel, index:channelIndex, oneCate })
    } catch (error) {
      // TODO
    }
    topLoading.value = false
    hasInitView.value = true
  }
  // 切换频道
  const changeChannel = async ({ channel, index, oneCate }) => {
    // console.log('channel',channel);
    leftLoading.value = true
    rightLoading.value = true
    activeChannel.value = { channel, index }
    try {
      const lefts = await getLeftContent(channel)
      leftContent.value = lefts
      // 批量获取二级导航数据
      if(cateType === 'sidecat' && lefts.contents && lefts.contents.length > 0){
        const rights = await getRightContentBatch(lefts)
        lefts.contents = lefts.contents.map(item => {
          const rightItem = rights[item.id]
          item.cover = rightItem.contents?.[0]?.child?.[0]?.props?.items?.[1]?.cover || {}
          return item
        })
        // 覆盖原有的缓存谁
        // cacheContainer.leftContent[channel.id] = leftContent.value
      }
      
      const leftIndex = oneCate || cacheContainer.channelOneCate[`${cateType}_${channel.id}`] || 0
      const curLeft = lefts?.contents?.[leftIndex] || lefts?.contents?.[0]
      changeLeftContent({ curLeft, index: leftIndex })
    } catch (error) {
      console.log(error)
    }
    leftLoading.value = false
  }
  // 获取左侧内容
  const getLeftContent = async curChannel => {
    const lefts = cacheContainer.leftContent[curChannel.id]
    if (lefts) {
      return lefts
    }
    // 获取左侧数据的参数
    const leftContentParams = {
      channelType: curChannel?.id,
      cat_id: curChannel?.cat_id,
    }
    // 如果缓存中有批量数据，直接取缓存数据
    let res = await apis.getLeftContent(leftContentParams)
    if (res.info?.contents && res.info?.contents.length > 0) {
      res.info.contents.map((item, index) => {
        item.saInfo = getOneCateSa(item, index)
        return item
      })
    }
    cacheContainer.leftContent[curChannel.id] = res.info || {}
    return res.info
  }
  // 切换左侧内容
  const changeLeftContent = async ({ curLeft, index }) => {
    activeLeftContent.value = {cate: curLeft, index}
    const channelId = activeChannel.value?.channel?.id
    cacheContainer.channelOneCate[`${cateType}_${channelId}`] = index

    const rights = await getRightContent(activeChannel.value.channel, curLeft)
    rightContent.value = rights
  }
  // 获取右侧内容
  const getRightContent = async (curChannel, curLeft) => {
    const cacheKey = `${curChannel.id}_${curLeft.id}`
    let rights = cacheContainer.rightContent[cacheKey]
    if (!rights) {
      // 获取右侧数据的参数
      rightLoading.value = true
      try {
        const rightContentParams = {
          id: curLeft?.id, // 左侧内容的id
          channelType: curChannel?.id, // 频道id
        }
        const res = await apis.getRightContent(rightContentParams)
        // eslint-disable-next-line require-atomic-updates
        rights = res.info || {}
        // TODO 对数据进行处理，增加埋点相关信息
        appendTwoCateSa(rights, activeChannel.value, activeLeftContent.value)
        cacheContainer.rightContent[cacheKey] = rights
      } catch (error) {
        console.log('getRightContent', error)
      }
    }
    rightLoading.value = false
    return rights
  }
  // 批量获取右侧内容
  const getRightContentBatch = async leftData => {
    const channelId = activeChannel.value?.channel?.id
    const ids = []
    const resObj = {}
    const leftMap = {}
    leftData.contents.forEach(item => {
      const key = `${channelId}_${item.id}`
      const rightItem = cacheContainer.rightContent[key]
      leftMap[item.id] = item
      if (!rightItem) {
        ids.push(item.id)
      } else {
        resObj[item.id] = rightItem
      }
    })
    if (ids.length === 0) return resObj
    try {
      const params = {
        channelType: channelId,
        ids,
      }
      const res = await apis.getRightContentBatch(params)
      ids.forEach(key => {
        const ckey = `${channelId}_${key}`
        const rights = res.info?.[key]
        const index = leftData?.contents?.findIndex(item => item.id === key)
        const curLeft = { cate:leftData?.contents[index],index }
        appendTwoCateSa(rights, activeChannel.value, curLeft)
        cacheContainer.rightContent[ckey] = rights
        resObj[key] = rights
      })
    } catch (error) {}
    return resObj
  }

  function clearCache(key){
    if(key){
      return  
    }
  }

  return {
    topNav,
    hasInitView,
    topLoading,
    leftLoading,
    rightLoading,
    activeChannel,
    activeLeftContent,
    leftContent,
    rightContent,
    initFsView,
    getTopNav,
    changeChannel,
    changeLeftContent,
    getRightContentBatch,
    clearCache
  }
}

export default useData
