<!-- eslint-disable max-lines-per-function -->
<script lang="jsx">
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getLink, getGenerateLLinkParams } from '../../../common/hooks/useLink.js'
import { transformImg, replaceHrefSpecChar, mergeQueryString } from '@shein/common-function'
import { getCutImg } from '../../../common/hooks/useCutImg.js'

export default {
  name: 'CateClassifyFunctional',
  functional: true,
  inject: ['analysisInstance'],
  props: {
    cate: {
      type: Array,
      default: () => [],
    },
    activeChannel: {
      type: Object,
      default: () => ({}),
    },
    activeLeftContent: {
      type: Object,
      default: () => ({}),
    },
    isExpandChild: {
      type: Boolean,
      default: false,
    },
    compIndex: {
      type: Number,
      default: 0,
    },
    cateType: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['jumpLink', 'expose'],
  render(_, { props, listeners, injections }) {
    const { vExpose, vTap } = injections['analysisInstance']

    const CLSSIFY_DESIGN_WIDTH = 132
    const CLSSIFY_MARK_DESIGN_WIDTH = 40
    const { cssRight = false } = useAppConfigs()?.$envs || {}

    const handleATagpreventDefault = $event => {
      $event && $event.preventDefault()
    }

    const cutImg = getCutImg()
    const getItemImgStyle = item => {
      if (!item) return {}
      let backgroundImage = ''
      if (item._preloadUrl) {
        backgroundImage = item._preloadUrl
      }
      if (!item.isLazy && item.cover?.src) {
        backgroundImage = item.cover?.src
      }
      if (backgroundImage) {
        if (typeof cutImg === 'function') {
          backgroundImage = cutImg(backgroundImage, CLSSIFY_DESIGN_WIDTH)
        }
        return { backgroundImage: `url(${backgroundImage})` }
      }
      return {}
    }

    const beforeNavChange = _href => {
      const cRegx = /.+-c-(\d+).+/
      let cat_id = cRegx.exec(_href)
      const { lang } = useAppConfigs()?.$envs || {}
      if (cat_id) {
        cat_id = cat_id[1]
      }

      if (['ar', 'ar-en', 'in', 'es', 'us', 'de', 'uk', 'fr', 'tw', 'en', 'hk', 'se', 'sesv', 'vn'].includes(lang)) {
        return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
      } else if (
        [
          'cl',
          'arg',
          'ru',
          'il',
          'ilen',
          'th',
          'then',
          'ca',
          'cafr',
          'eur',
          'eurfr',
          'eurde',
          'eurnl',
          'eurpt-pt',
        ].includes(lang)
      ) {
        let siteFix = lang
        if (['th', 'then'].includes(lang)) siteFix = 'th'
        if (['il', 'ilen'].includes(lang)) siteFix = 'il'
        if (['ca', 'cafr'].includes(lang)) siteFix = 'ca'
        if (['eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(lang)) siteFix = 'eur'
        // 若链接中已含有站点，则不再添加站点

        const isSiteInclude = (url, site) => {
          const reg = new RegExp(`/${site}/`)
          return reg.test(url)
        }

        if (isSiteInclude(_href, siteFix)) {
          return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
        } else {
          return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '/' + siteFix) })
        }
      }
      return replaceHrefSpecChar({ href: _href })
    }

    const addUrlParams = ({ url = '', pos = '', id = '' } = {}) => {
      const params = { pageFrom: pos }

      if (id) {
        Object.assign(params, {
          adp: id,
        })
      }

      url = mergeQueryString({
        mergeObj: params,
        url,
      })
      url = beforeNavChange(url)

      return url
    }
    let lockClick = false
    const clickItem = ({ cate, index, type }) => {
      if(lockClick) return
      if(type === 'click'){
        lockClick = true
      }
      let url = getLink({
        item: cate?.categoryType,
        cateLinks: {},
        compIndex: props.compIndex,
        index: -1,
      })
      url = mergeQueryString({
        mergeObj: getGenerateLLinkParams({
          cate,
          cateType: props.cateType,
          channel: props.activeChannel?.channel,
          channelIndex: props.activeChannel?.channelIndex,
          oneCateContent: props.activeLeftContent,
          compIndex: props.compIndex,
          oneCate: props.activeChannel?.oneCate,
        }),
        url,
      })
      // 推荐图 需要拼接额外参数 goodsId 作为adp 列表页置顶
      if (cate.autoRecommend) {
        url = addUrlParams({ url, pos: props.cateType, id: cate.cover?.goodsId })
      }
      const closeDrawer = cate.categoryType.hrefType != 'noJump'
      listeners.jumpLink?.({ url, hrefType: cate?.categoryType?.hrefType }, { type, closeDrawer })
      setTimeout(() => {        
        lockClick = false
      }, 200);
    }
    const eid = `${props.cateType}_${props.activeChannel.index}`
    // da-eid={`${eid}_${threeCate.id}`}
    return (
      <ul class="bs-cate__classify-wrap">
        {props.cate.map((threeCate, threeIndex) => (
          <li
            key={`${eid}_${threeCate.id}`}
            class={['cate-classify__item', { 'j-expand-title-child': props.isExpandChild }]}
            vOn:click={e => {
              e.stopPropagation()
              clickItem({ cate: threeCate, index: threeIndex, type: 'click' })
            }}
            vOn:pointerover={() => clickItem({ cate: threeCate, index: threeIndex, type: 'pointerover' })}
            {...{
              directives: [
                {
                  name: 'expose',
                  // 注册指令
                  def: vExpose,
                  value: {
                    id: 'expose_second_category.comp_category',
                    data: {
                      item: threeCate,
                      topNavSa: props.activeChannel?.channel?.saInfo,
                      oneCateContent: props.activeLeftContent?.cate?.saInfo,
                      cateType: props.cateType,
                    },
                    code: props.cateType,
                    delayReady: 300,
                    callback: listeners.expose,
                  },
                },
                {
                  name: 'tap',
                  // 注册指令
                  def: vTap,
                  value: {
                    id: 'click_second_category.comp_category',
                    data: {
                      item: threeCate,
                      topNavSa: props.activeChannel?.channel?.saInfo,
                      oneCateContent: props.activeLeftContent?.cate?.saInfo,
                      cateType: props.cateType,
                    },
                  },
                },
              ],
            }}
          >
            <a
              class="cate-classify__wrap"
              tabindex="0"
              role="button"
              aria-label={threeCate.categoryLanguage}
              vOn:click={handleATagpreventDefault}
            >
              <div
                key={threeCate?.cover?.src}
                class={[
                  'cate-classify__img',
                  { lazyload: threeCate.isLazy && threeCate?.cover?.src, border: threeCate.autoRecommend },
                  'fsp-element',
                ]}
                data-background-image={threeCate?.cover?.src}
                data-design-width={CLSSIFY_DESIGN_WIDTH}
                style={getItemImgStyle(threeCate)}
              >
                {threeCate.angle?.src && (
                  <em
                    class="cate-classify__corner-mark lazyload"
                    style={cssRight ? { left: 0 } : { right: 0 }}
                    data-background-image={transformImg({ img: threeCate.angle?.src })}
                    data-design-width={CLSSIFY_MARK_DESIGN_WIDTH}
                  ></em>
                )}
              </div>
              <div class={['cate-classify__name', { zIndex: !props.isActive }]}>{threeCate.categoryLanguage}</div>
            </a>
          </li>
        ))}
      </ul>
    )
  },
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */

.bs-cate__classify-wrap {
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  background: #fff;
  // border: 1px solid red;
  .cate-classify {
    &__item {
      // border: 1px solid red;
      width: 33.333%;
      padding: 0;
      margin-bottom: 4vw;
      margin-bottom: min(4vw, 21.6px);
    }

    &__wrap {
      text-decoration: none;
      display: block;
    }

    &__img {
      width: 17.6vw;
      height: 17.6vw;
      width: min(17.6vw, 95px);
      height: min(17.6vw, 95px);
      margin: 0 auto;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
      background-color: #f3f3f3;
      background-position: center;
      &.border {
        border: 0.5px solid #e4e4e4;
      }
    }
    &__corner-mark {
      position: absolute;
      top: 0;
      width: 5.3vw;
      height: 5.3vw;
      width: min(5.3vw, 28.8px);
      height: min(5.3vw, 28.8px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &__name {
      position: relative;
      line-height: 3.567vw;
      margin-top: 0.53vw;
      line-height: min(3.567vw, 19.26px);
      margin-top: min(0.53vw, 3px);
      text-align: center;
      font-weight: 400;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      max-height: 11.6vw;
      max-height: min(11.6vw, 62.64px);
      width: 100%;
      font-size: 3.2vw;
      font-size: min(3.2vw, 17.28px);
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      &.zIndex {
        z-index: 10;
      }
    }
  }
}
</style>
